import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const AppAdminCards = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>Hydra App Admin</Typography>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <Typography variant="h5" component="h2">Applications</Typography>
                <Typography variant="body2" component="p">Manage applications in Hydra</Typography>
              </CardContent>
              <CardActions>
                <Link to={`${path}/apps`}>
                  <Button size="small" color="primary">Open</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
    
  );
};

export default AppAdminCards;