import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { get, map } from 'lodash';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import { request } from '../../../../../helpers/request';
import ApplicationCard from '../../../../../common/ApplicationCard';
import ErrorMessage from '../../../../../common/ErrorMessage';

const Search = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [applications, setApplications] = useState([]);
  const { path } = useRouteMatch();

  const listApplications = () => {
    setIsLoading(true);
    setErrorMessage('');
    setApplications([]);
    return request(`/applications`, { method: 'GET' })
      .then((res) => {
        setIsLoading(false);
        setApplications(get(res, 'data', []));
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error);
      });
  };

  useEffect(() => {
    listApplications();
  }, []);

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          <Fab color="primary" size="small" style={{ marginRight: '10px' }} href={`/#${path}/create`}>
            <AddIcon />
          </Fab>
          Hydra Applications
          {isLoading && <CircularProgress />}
        </Typography>
        {errorMessage ? <ErrorMessage error={errorMessage} /> : null }
        <Grid container direction="row" spacing={2}>
          {map(applications, (app, id) => (
            <Grid item key={id}>
              <ApplicationCard
                app={{
                  ...app,
                  display_name: `Edit ${app.display_name}`,
                  href: `/#${path}/${id}`,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Search;