import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { chain, get, map, filter, keys, uniq, cloneDeep } from 'lodash';
import { request } from '../../../../../helpers/request';
import ApplicationCard from '../../../../../common/ApplicationCard';
import ApplicationDetails from '../ApplicationDetails';
import ApplicationRole from './ApplicationRole';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const Edit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [application, setApplication] = useState({});
  const [selectedRole, setSelectedRole] = useState(null);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const classes = useStyles();
  const { app_id } = useParams();

  const getApplication = (app_id) => {
    if (!app_id) return;
    setIsLoading(true);
    setErrorMessage('');
    setApplication([]);
    return request(`/applications/${app_id}`, { method: 'GET' })
      .then((res) => {
        setIsLoading(false);
        const app = get(res, 'data', {});
        app.id = app_id;
        setApplication(app);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.message || 'unknown');
      });
  };

  const getGroups = () => {
    setIsLoading(true);
    setErrorMessage('');
    setGroups([]);
    return request('/groups', { method: 'GET' })
      .then((res) => {
        setIsLoading(false);
        const groups = chain(res)
          .get('data', [])
          .cloneDeep()
          .value();
        setGroups(groups);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.message || 'unknown');
      });
  };

  useEffect(() => {
    getApplication(app_id);
  }, [app_id]);

  useEffect(() => {
    getGroups();
  }, [application.name]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const applicationRoles = keys(get(application, 'roles', []));

    const availableRoles = filter(groups, (g) => applicationRoles.indexOf(g) === -1);
    setAvailableRoles(availableRoles);
  }, [groups, selectedRole, application]);

  return (
    <>
      <Box my={4} mx={4}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h4" component="h1" gutterBottom>Application Details</Typography>
            { errorMessage ? <Typography color="primary" component="h1" style={{ backgroundColor: 'red' }}>Error: {errorMessage}</Typography> : null }
            {
              (isLoading) ?
              <CircularProgress /> :
              <ApplicationDetails
                classes={classes}
                groups={groups}
                application={application}
                setApplication={setApplication}
                showDelete
              />
            }
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4" component="h1" gutterBottom>Preview</Typography>
            <ApplicationCard app={{ ...application, target: '_blank' }} />
          </Grid>
        </Grid>
      </Box>
      <Box my={4} mx={4}>
        <Card className={classes.root}>
          <CardHeader title="Application Roles" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Autocomplete
                  id="available-roles"
                  options={availableRoles}
                  getOptionLabel={(option) => option}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      size="small"
                      label="Role"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  onChange={(event, value) => setSelectedRole(value)}
                  value={selectedRole}
                />
              </Grid>
              <Grid item xs={1}>
                <Fab
                  color="primary"
                  size="small"
                  disabled={!selectedRole || !selectedRole.length}
                  onClick={() => {
                    setApplication({
                      ...application,
                      roles: {
                        ...application.roles,
                        [selectedRole]: [],
                      },
                    });
                    setSelectedRole(null);
                  }}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box my={4} mx={4}>
        <Typography variant="h4" component="h1" gutterBottom>Application Roles</Typography>
      </Box>
      {map(application.roles, (permissions, role) => (
        <Box my={4} mx={4} key={`role-card-${role}`}>
          <ApplicationRole
            role={role}
            permissions={uniq(permissions.sort())}
            setPermissions={(role, permissions) => {
              setApplication({
                ...application,
                roles: {
                  ...application.roles,
                  [role]: permissions,
                },
              });
            }}
            deleteRole={() => {
              const updated = cloneDeep(application);
              delete updated.roles[role];
              setApplication(updated);
            }}
          />
        </Box>
      ))}
    </>
  );
};

export default Edit;
