import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CardActions from '@material-ui/core/CardActions';
import { red } from '@material-ui/core/colors';
import { map, uniq } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
  },
}));

const ApplicationRole = ({ role, permissions, setPermissions, deleteRole }) => {
  const [expanded, setExpanded] = useState(false);
  const [currentPermission, setCurrentPermission] = useState('');
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <>
            <IconButton onClick={deleteRole}>
              <DeleteIcon style={{ color: red[500] }} />
            </IconButton>
            <IconButton
              className={expanded ? classes.expandOpen : classes.expand}
              onClick={() => setExpanded(!expanded)}
            >
              <ExpandMoreIcon />
            </IconButton>
          </>
        }
        title={role}
      />
      <CardActions disableSpacing>
        
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <TextField
                id="available-roles"
                size="small"
                label="Permission"
                variant="outlined"
                onChange={(event) => setCurrentPermission(`${event.target.value}`.replace(' ', '_'))}
                value={currentPermission}
                fullWidth
              />
            </Grid>
            <Grid item xs={1}>
              <Fab
                color="primary"
                size="small"
                disabled={!currentPermission.length}
                onClick={() => {
                  setPermissions(role, uniq([...permissions].concat(currentPermission)));
                  setCurrentPermission('');
                }}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <List>
              {map(permissions, permission => (
                <ListItem key={`${role}-${permission}`}>
                  <ListItemText primary={permission} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setPermissions(role, permissions.filter(p => p !== permission));
                      }}
                    >
                      <DeleteIcon style={{ color: red[500] }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ApplicationRole;
