import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import { request } from '../../../../helpers/request';

// Important! These are configured (for now) in AppIcon/index.js
const availableIcons = [
  'view-quilt',
  'language',
  'event',
  'assignment',
  'maps:directions-car',
  'account-circle',
  'explore',
  'editor:insert-chart',
  'event-note',
  'icons:donut-large',
  'image:slideshow',
  'image:camera-alt',
  'description',
  'icons:shopping-cart',
  'hardware:headset-mic',
  'icons:folder',
  'cloud',
  'icons:dns',
  'timeline',
  'editor:multiline-chart',
  'monitor',
  'tune',
  'cog',
  'LocalGasStation',
  'format-list-numbered-rtl',
];

const ApplicationDetails = ({ classes, application, setApplication, groups, didSave, showDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);

  const saveApplication = (application) => {
    setIsLoading(true);
    setErrorMessage('');
    return request(`/applications/${application.id}`, { method: 'PUT', data: application })
      .then((res) => {
        setIsLoading(false);
        if (didSave) didSave();
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.message || 'unknown');
      });
  };

  const removeApplication = (application) => {
    setIsLoading(true);
    setErrorMessage('');
    return request(`/applications/${application.id}`, { method: 'DELETE' })
      .then((res) => {
        setIsLoading(false);
        setIsDeleted(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.message || 'unknown');
      });
  };
  return (
    <Card className={classes.root}>
      <CardHeader title={get(application, 'display_name', 'N/A')} />
      <CardContent>
        <form className={classes.form} noValidate autoComplete="off" style={{ padding: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="group-autocomplete"
                options={groups}
                getOptionLabel={(option) => option}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    size="small"
                    label="Access Group"
                    variant="outlined"
                    fullWidth
                    required
                  />
                }
                onChange={(event, value) => setApplication({ ...application, name: value })}
                value={get(application, 'name', '')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Application Name"
                variant="outlined"
                onChange={(event) => setApplication({ ...application, display_name: event.target.value })}
                value={get(application, 'display_name', '')}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="icon-autocomplete"
                options={availableIcons}
                getOptionLabel={(option) => option}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    size="small"
                    label="Icon"
                    variant="outlined"
                    fullWidth
                    required
                  />
                }
                onChange={(event, value) => setApplication({ ...application, icon: value })}
                value={get(application, 'icon', '')}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel htmlFor="href">URL</InputLabel>
              <TextField
                id="href"
                size="small"
                onChange={(event) => setApplication({ ...application, href: event.target.value })}
                value={get(application, 'href', '')}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel htmlFor="color">Color</InputLabel>
              <Input
                id="color"
                type="color"
                size="small"
                onChange={(e) => setApplication({ ...application, color: e.target.value })}
                value={get(application, 'color', '')}
                style={{ width: 100 }}
                required
              />
            </Grid>
          </Grid>
        </form>
        { errorMessage ? <Typography color="primary" component="h1" style={{ backgroundColor: 'red' }}>Error: {errorMessage}</Typography> : null }
        { isDeleted ? <Redirect to="/app-admin/apps" /> : null }
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={() => saveApplication(application) } disabled={isLoading}>Save</Button>
        { showDelete ? <Button color="secondary" onClick={() => removeApplication(application) } disabled={isLoading}>Delete</Button> : null }
      </CardActions>
    </Card>
  );
};

export default ApplicationDetails;
