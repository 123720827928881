const _ = require('lodash');

export const userDetails = JSON.parse(localStorage.getItem('user_details'));

const _requestOptions = ({ method, data = null }) => {
  const request = {
    credentials: 'include',
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userDetails.token}`,
    },
  };
  if (data) request.body = JSON.stringify(data);
  return request;
};

export function triggerNotAuthorized() {
  const e = new CustomEvent('iron-signal', { detail: { name: 'not-authorized' } });
  document.dispatchEvent(e);
}

export const request = (path, options) => {
  const host = window.location.host;
  let protocol = window.location.protocol;
  let domain = (_.startsWith(host, 'localhost')) ? 'localhost:3000': host;
  let api_path = '/api/v1/auth';
  if (options.protocol) protocol = options.protocol;
  if (options.domain) domain = options.domain;
  if (options.api_path) api_path = options.api_path;
  return fetch(`${protocol}//${domain}${api_path}${path}`, _requestOptions(options))
    .then((result) => {
      switch (result.status) {
        case 400:
          return result.json()
            .then((response) => {
              let errorMessage = '';
              if (_.has(response, 'errors')) {
                errorMessage = _.get(response, 'errors[0]', 'unknown error');
              } else {
                errorMessage = _.get(response, 'message', 'unknown error');
              }
              throw new Error(errorMessage);
            });
        case 401:
          triggerNotAuthorized();
          throw new Error('not authorized');
        case 403:
          throw new Error('Forbidden - No Access');
        case 404:
          throw new Error('API Endpoint Not Found');
        case 500:
          throw new Error('Server Error');
        case 502:
        case 503:
          throw new Error('Bad Gateway');
        default:
      }
      return result.json();
    });
};
