import React from 'react';
import { Typography } from '@material-ui/core';

const ErrorMessage = ({ error }) => {
  let errorMessage = 'Unknown error';
  if (error.message) errorMessage = error.message;
  return <Typography variant="h5"><strong>Error:</strong> {errorMessage}</Typography>
};

export default ErrorMessage;
