import React, { Suspense } from 'react';

import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import Search from './Search';
import Create from './Create';
import Edit from './Edit';

const Apps = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<h1>Loading . . .</h1>}>
      <Switch>
        <Route exact path={path} component={Search} />
        <Route path={`${path}/create`} component={Create} />
        <Route path={`${path}/:app_id`} component={Edit} />
      </Switch>
    </Suspense>
  );
};

export default Apps;
