import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import AppAdminCards from './AppAdminCards';
import Apps from './Apps';

const AppAdmin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={AppAdminCards} />
      <Route path={`${path}/apps`} component={Apps} />
    </Switch>
  );
};

export default AppAdmin;
