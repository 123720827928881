import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { chain } from 'lodash';
// import MD5 from 'crypto-js/md5';
import { request } from '../../../../../helpers/request';
import md5 from '../../../../../helpers/md5';
import ApplicationCard from '../../../../../common/ApplicationCard';
import ApplicationDetails from '../ApplicationDetails';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const mockApplication = {
  id: false,
  name: '',
  display_name: 'New Application',
  icon: 'explore',
  color: '#FF33CC',
  href: '/application/',
  roles: {},
};

const Create = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [application, setApplication] = useState(mockApplication);
  const [groups, setGroups] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (application.id) return;
    setApplication({
      ...application,
      id: md5(new Date().getTime()),
    });
  }, []); // eslint-disable-line

  const getGroups = () => {
    setIsLoading(true);
    setErrorMessage('');
    setGroups([]);
    return request('/groups', { method: 'GET' })
      .then((res) => {
        setIsLoading(false);
        const groups = chain(res)
          .get('data', [])
          .cloneDeep()
          .value();
        setGroups(groups);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error.message || 'unknown');
      });
  };

  useEffect(() => {
    getGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box my={4} mx={4}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h4" component="h1" gutterBottom>Application Details</Typography>
          { errorMessage ? <Typography color="primary" component="h1" style={{ backgroundColor: 'red' }}>Error: {errorMessage}</Typography> : null }
          { isCreated ? <Redirect to='/app-admin/apps' /> : null }
          {
            (isLoading) ? 
            <CircularProgress /> : 
            <ApplicationDetails
              classes={classes}
              groups={groups}
              application={application}
              setApplication={setApplication}
              didSave={() => setIsCreated(true)}
              showDelete={false}
            />
          }
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" component="h1" gutterBottom>Preview</Typography>
          <ApplicationCard app={{ ...application, target: '_blank' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Create;
