import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import HydraToolbar from '@trd/hydra-toolbar-react';
import Routes from './Routes';
import './App.css';

const App = () => {
  return (
    <>
      <HydraToolbar
        toolbarTitle="Hydra"
        onLogOut={() => window.location.reload()}
        onSignIn={() => window.location.reload()}
        disableHelpGuide
      />
      <Router>
        <Routes />
      </Router>
    </>
  );
};

export default App;
