import React from 'react';
import { get } from 'lodash';

import AppIcon from '../AppIcon';
import './ApplicationCard.css';

const ApplicationCard = ({ app }) => {
  const href = get(app, 'href', '');
  return (
    <a
      target={get(app, 'target', '_self')}
      rel="noreferrer"
      className="app-card"
      href={href}
    >
      <div className="card" style={{ backgroundColor: get(app, 'color', '#f3c') }}>
        <div className="icon">
          <AppIcon icon={app.icon} />
        </div>
        <p className="name">{get(app, 'display_name', 'N/A')}</p>
      </div>
    </a>
  );
};

export default ApplicationCard;
