import React from 'react';
import { map, filter } from 'lodash';
import ApplicationCard from '../../../common/ApplicationCard';

const retrieveApps = () => JSON.parse(localStorage.user_details).apps;
const retrieveAppsPermissions = () => JSON.parse(localStorage.user_details).appsPermissions;

/**
 * Don't show TRD Events to users who have read access but no write (admin) access
*/
const limitTrdEventsVisibility = (app) => {

  const trdEventsAppName = 'TRD Events';
  const appsPermissions = retrieveAppsPermissions();
  if (app.display_name === trdEventsAppName && appsPermissions[trdEventsAppName]) {
    const permissions = appsPermissions[trdEventsAppName].access;
    return permissions.includes('update_events');
  }
  return true;
};

const Dashboard = () => {
  let content;
  try {
    const visibleApps = filter(retrieveApps(), limitTrdEventsVisibility);
    content = map(visibleApps, (app, i) => <ApplicationCard app={app} key={app.name + i} />);
  } catch (e) {
    content = null
  }
  return (
    <div className="container">
      {content}
    </div>
  );
};

export default Dashboard;
