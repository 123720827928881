import React from 'react';

import {
  AccountCircle as AccountCircleIcon,
  Assignment as AssignmentIcon,
  Event as EventIcon,
  Explore as ExploreIcon,
  DirectionsCar as DirectionsCarIcon,
  DonutLarge as DonutLargeIcon,
  EventNote as EventNoteIcon,
  InsertChart as InsertChartIcon,
  Language as LanguageIcon,
  Slideshow as SlideshowIcon,
  ViewQuilt as ViewQuiltIcon,
  CameraAlt as CameraAltIcon,
  Description as DescriptionIcon,
  ShoppingCart as ShoppingCartIcon,
  HeadsetMic as HeadsetMicIcon,
  Folder as FolderIcon,
  Cloud as CloudIcon,
  Dns as DnsIcon,
  Timeline as TimelineIcon,
  MultilineChart as MultilineChartIcon,
  DesktopWindows as DesktopWindowsIcon,
  TuneSharp as TuneSharpIcon,
  Settings as SettingsIcon,
  LocalGasStation as LocalGasStationIcon,
  FormatListNumberedRtl as FormatListNumberedRtlIcon
} from '@material-ui/icons';

const AppIcon = ({ icon }) => {
  switch (icon) {
    case 'view-quilt': return <ViewQuiltIcon />;
    case 'language': return <LanguageIcon />;
    case 'event': return <EventIcon />;
    case 'assignment': return <AssignmentIcon />;
    case 'maps:directions-car': return <DirectionsCarIcon />;
    case 'account-circle': return <AccountCircleIcon />;
    case 'explore': return <ExploreIcon />;
    case 'editor:insert-chart': return <InsertChartIcon />;
    case 'event-note': return <EventNoteIcon />;
    case 'icons:donut-large': return <DonutLargeIcon />;
    case 'image:slideshow': return <SlideshowIcon />;
    case 'image:camera-alt': return <CameraAltIcon />;
    case 'description': return <DescriptionIcon />;
    case 'icons:shopping-cart': return <ShoppingCartIcon />;
    case 'hardware:headset-mic': return <HeadsetMicIcon />;
    case 'icons:folder': return <FolderIcon />;
    case 'cloud': return <CloudIcon />;
    case 'icons:dns': return <DnsIcon />;
    case 'timeline': return <TimelineIcon />;
    case 'editor:multiline-chart': return <MultilineChartIcon />;
    case 'monitor': return <DesktopWindowsIcon />;
    case 'tune': return <TuneSharpIcon />;
    case 'cog': return <SettingsIcon />;
    case 'LocalGasStation': return <LocalGasStationIcon />;
    case 'format-list-numbered-rtl': return <FormatListNumberedRtlIcon />;
    default: return null;
  }
};

export default AppIcon;
