import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import AppAdmin from './AppAdmin';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/app-admin" component={AppAdmin} />
      <Route path="*">
        <h1>Page Not Found</h1>
      </Route>
    </Switch>
  );
};

export default Routes;
